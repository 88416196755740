<!-- 选择头像框 -->
<template>
    <div class="emperor-main" @touchmove.prevent>
        <div class="emperor-content">
            <img :src="imgUrl('pc-emperor-title')" class="pc-emperor-title">
            <img :src="imgUrl('pc-emperor-close-btn')" class="pc-emperor-close-btn" @click="handleClickCloseBtn">
            <div class="pc-emperor-detail">
                <img :src="imgUrl(`${emperorStateImg}`)" class="pc-emperor-detail-img">
                <div v-if="leftDay" class="pc-leftDay">{{leftDayNotice}} <span
                        class="height-light">剩余{{leftDay}}天</span></div>
                <img :src="getEmperorImg()" class="pc-emperor-detail-icon">
                <input class="pc-userNoInput" placeholder="请输入天狼数字ID" v-model="showUserInfo" />
                <input type="tel" class="pc-userPhoneInput" placeholder="请输此ID绑定的手机号码" v-model="userPhone" />
                <div class="pc-checkUserInfoBtn" @click="handleClickConfirmBtn">确认</div>
                <div class="pc-detail-top">{{topDescribe}}</div>
                <div v-if="midDescribe" class="pc-detail-mid">{{midDescribe}}</div>
                <div class="recharge-btn">
                    <img v-if="rechargeImg" :src="imgUrl(`emperor-${rechargeImg}`)" class="pc-recharge-img"
                        @click="handleClickRechargeEmperor(rechargeImg)">
                </div>

                <div class="pc-detail-bottom">贵族权益详情请查看app内贵族页面</div>
            </div>
        </div>
    </div>
</template>


<script>
    import {
        setCookie
    } from "./../../utils/utils";
    import {
        getParam
    } from "./../../utils/utils";
    import {
        godEmperorInfo,
        getUserios,
        successShow
    } from "./../../apis/officialWeb";
    export default {
        data() {
            return {
                // userNo: "7345747",
                // userPhone: "99109923",
                // userNo: "6666903",
                // userPhone: "13522202300",
                // userNo: "46",
                // userPhone: "139410317343123",
                showUserInfo: "",
                userNo: "",
                userPhone: "",
                topDescribe: "开通神皇贵族",
                midDescribe: "超多权益等你解锁",
                nobleLevel: null,
                nobleLight: 0,
                leftDay: 0,
                leftDayNotice: "",
                nobleEndTime: "",
                godCacheEndTime: "",
                rechargeImg: "",
                godCacheLeftDay: "",
                nobleLeftDay: "",
                payGodEmperorState: 0, //支付记录
                emperorStateImg: "emperor-open",
            };
        },
        watch: {

        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {
            let out_trade_no = getParam("out_trade_no");
            let _this = this;
            if (out_trade_no) {
                successShow({
                    outTradeNo: out_trade_no
                }).then(res => {
                    // console.log(res);
                    if (res.data.isFirstShow == 1) {
                        _this.showUserInfo = res.data.userNo;
                        _this.userPhone = res.data.phone;
                        _this.handleClickConfirmBtn();
                    }
                })
            }
        },
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {

        },
        onShow() {
            // console.log('sss');
        },
        components: {

        },
        destroyed() {
            // Object.assign(this.$data, this.$options.data());
        },
        methods: {
            handleClickRechargeEmperor: function (type) {
                //16购买  17续费
                let index = 0;
                if (type == "4998") {
                    index = 17;
                } else if (type == "19998") {
                    index = 16;
                } else {
                    return;
                }
                getUserios({
                    userNo: this.userNo,
                    type: index
                }).then(response => {
                    // console.log(response);
                    if (response.sign == "0") {
                        let div = document.createElement("div");
                        let msg =
                            response.msg.split("/alipay/")[0] +
                            "https://werewolf.53site.com/alipay/" +
                            response.msg.split("/alipay/")[1];
                        div.innerHTML = msg;
                        document.body.appendChild(div);
                        document.forms[0].submit();
                    } else {
                        window.alert(response.msg);
                    }
                });
            },
            handleClickCloseBtn: function () {
                this.$parent.showPcOpeningEmperor = false;
            },
            handleClickConfirmBtn: function () {
                if (!this.showUserInfo || !this.userPhone) {
                    return;
                }
                var isnum = /^\d+$/.test(this.showUserInfo);
                if (!isnum) {
                    return;
                }
                this.userNo = this.showUserInfo;
                let _this = this;
                //调接口查询贵族信息
                godEmperorInfo({
                    userNo: this.userNo,
                    phone: this.userPhone
                }).then(res => {
                    // console.log(res);
                    // console.log(res.data);
                    if (res.code == 1) {
                        _this.showUserInfo = res.data.nickname;
                        _this.nobleLevel = res.data.nobleLevel;
                        _this.nobleLight = res.data.nobleLight;
                        _this.nobleEndTime = res.data.nobleEndTime;
                        _this.godCacheEndTime = res.data.godCacheEndTime;
                        _this.payGodEmperorState = res.data.payGodEmperorState;
                        _this.nobleLeftDay = res.data.nobleLeftDay;
                        _this.godCacheLeftDay = res.data.godCacheLeftDay;
                        _this.initNobleLevel();
                    } else {
                        window.alert(res.msg);
                    }
                })
            },
            getEmperorImg: function () {
                switch (this.nobleLevel) {
                    case 6: //黑皇
                        return this.imgUrl(`emperor-black${this.nobleLight ? '-n' : '-b'}`);
                    case 7: //紫皇
                        return this.imgUrl(`emperor-purple${this.nobleLight ? '-n' : '-b'}`);
                    case 8: //金皇
                        return this.imgUrl(`emperor-gold${this.nobleLight ? '-n' : '-b'}`);
                    default:
                        return this.imgUrl('emperor-black-b');
                }
            },
            getDaysBetween(dateString) {
                let curDate = new Date();
                let startDate = Date.parse(curDate);
                let endDate = Date.parse(dateString);
                let days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
                if (days < 0) {
                    days = 0;
                }
                return Math.ceil(days);
            },
            initNobleLevel() {
                // console.log('this.nobleLevel=>', this.nobleLevel);
                // this.topDescribe = 
                if (this.nobleLevel > 5) {
                    this.topDescribe = "续费神皇贵族"
                    if (this.nobleLight) {
                        this.leftDayNotice = "神皇贵族特权";
                         this.leftDay = this.nobleLeftDay;

                    } else {
                        this.leftDayNotice = "神皇贵族缓冲期";
                         this.leftDay = this.godCacheLeftDay;
                    }

                    if (this.payGodEmperorState == 1) {
                        //刚激活神皇，二次进入页面，等待神皇贵族开通中
                        this.midDescribe = "等待神皇贵族开通中...";
                    } else {
                        this.emperorStateImg = "emperor-renew-2";
                        this.midDescribe = "";
                        //显示续费按钮
                        this.rechargeImg = "4998";
                    }

                } else {
                    this.leftDayNotice = "";
                    this.leftDay = 0;
                    this.emperorStateImg = "emperor-open";
                    if (this.nobleLevel == 5) {
                        this.topDescribe = "开通神皇贵族";
                        if (this.payGodEmperorState == 1) {
                            //刚激活神皇，二次进入页面，等待神皇贵族开通中
                            this.midDescribe = "等待神皇贵族开通中...";
                            this.rechargeImg = 0;
                        } else {
                            //显示充值按钮
                            this.midDescribe = "";
                            this.rechargeImg = "19998";
                        }

                    } else {
                        this.rechargeImg = 0;
                        this.topDescribe = "很抱歉"
                        this.midDescribe = "您的ID尚未达到帝王贵族，尚不满足开通神皇贵族的条件";
                    }
                }
            }
        }
    };
</script>
<style scoped lang='scss'>
    @import "../../style/main.scss";

    .emperor-main {
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .emperor-content {
        width: 909px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .pc-emperor-title {
        width: 285px;
        margin-left: 18px;
    }

    .pc-emperor-close-btn {
        width: 51px;
        position: absolute;
        right: 0;
        margin-top: 49px;
    }

    .pc-emperor-detail {
        width: 909px;
        height: 545px;
        margin-top: 11px;
        @include imgbg(imgUrl("pc-emperor-detail-bg.png"));
        border-radius: 3vw;
    }

    .pc-emperor-detail-img {
        width: 505.69px;
        height: 345px;
        position: fixed;
        border-radius: 3vw 0 0 0;
    }

    .pc-leftDay {
        width: 309px;
        height: 54px;
        position: absolute;
        margin-left: 558px;
        margin-top: 67px;
        line-height: 54px;
        font-size: 16px;
        text-align: center;
        color: black;
    }

    .height-light {
        font-size: 19px;
        color: #68440F;
    }

    .pc-emperor-detail-icon {
        position: absolute;
        width: 150px;
        height: 153px;
        margin-top: -77px;
        margin-left: 637px;
    }

    .pc-userNoInput {
        width: 309px;
        height: 54px;
        border-radius: 14px;
        border: none;
        background-color: #2D2922;
        color: white;
        position: absolute;
        margin-top: 131px;
        margin-left: 558px;
        outline: none;
        font-size: 16px;
        text-align: center;
    }

    .pc-userPhoneInput {
        width: 309px;
        height: 54px;
        border-radius: 14px;
        border: none;
        background-color: #2D2922;
        color: white;
        position: absolute;
        margin-top: 196px;
        margin-left: 558px;
        outline: none;
        font-size: 16px;
        text-align: center;
    }

    .pc-checkUserInfoBtn {
        width: 309px;
        height: 54px;
        border-radius: 14px;
        background-color: #FAE4C5;
        text-align: center;
        line-height: 54px;
        font-size: 19px;
        color: #68440F;
        position: absolute;
        margin-top: 262px;
        margin-left: 558px;
    }

    .pc-detail-top {
        width: 200px;
        font-size: 18px;
        color: #E2C9A4;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 362px;
        line-height: 18px;
        font-weight: 400;
    }

    .pc-detail-mid {
        width: 900px;
        font-size: 33px;
        color: #FFC04F;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 426px;
        line-height: 33px;
        font-weight: 400;
    }

    .pc-detail-bottom {
        width: 900px;
        font-size: 18px;
        color: #E2C9A4;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 503px;
        line-height: 18px;
        font-weight: 400;
    }

    .recharge-btn {
        width: 900px;
        position: absolute;
        margin-top: 410px;
        text-align: center;
    }

    .pc-recharge-img {
        width: 350px;
    }

    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
    }

    input::placeholder {
        color: #b19369;
    }

    input {
        -webkit-user-select: auto !important;
        -webkit-appearance: none !important;
    }
</style>